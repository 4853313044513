(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sportinfo/game-analyses-redux/assets/game-analyses-reducer.js') >= 0) return;  svs.modules.push('/components/sportinfo/game-analyses-redux/assets/game-analyses-reducer.js');
"use strict";

const _excluded = ["authorProfile", "gameAnalyses"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var n = Object.getOwnPropertySymbols(e); for (r = 0; r < n.length; r++) o = n[r], -1 === t.indexOf(o) && {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (-1 !== e.indexOf(n)) continue; t[n] = r[n]; } return t; }

const {
  createReducer
} = RTK;
const {
  fetchDrawAnalyses,
  fetchDrawData,
  setDrawData
} = svs.components.sportinfo.gameAnalysesRedux.actions;
const {
  ReducerState
} = svs.components.sport.tipsenShared;
const initial = {
  matchAnalysesState: {},
  drawAnalysesState: {},
  drawAnalyses: {},
  matchAnalyses: {},
  authorProfiles: {},
  drawData: {},
  byMatchId: {},
  byDraw: {},
  lastFetchByMatch: {},
  lastFetchByDraw: {}
};
const drawKeyFromAction = action => "".concat(action.meta.arg.productId, "_").concat(action.meta.arg.drawNumber);
const gameAnalysesReducer = createReducer(initial, builder => {
  builder.addCase(fetchDrawAnalyses.pending, (state, action) => {
    const key = drawKeyFromAction(action);
    state.drawAnalysesState[key] = ReducerState.Pending;
  }).addCase(fetchDrawAnalyses.rejected, (state, action) => {
    const key = drawKeyFromAction(action);
    state.drawAnalysesState[key] = ReducerState.Rejected;
  }).addCase(fetchDrawAnalyses.fulfilled, (state, action) => {
    var _action$meta$enhanche;
    const key = drawKeyFromAction(action);
    for (const drawAnalysis of action.payload.normalisedGameAnalyses) {
      const {
          authorProfile,
          gameAnalyses
        } = drawAnalysis,
        restOfDrawAnalysis = _objectWithoutProperties(drawAnalysis, _excluded);
      state.drawAnalyses[restOfDrawAnalysis.id] = _objectSpread(_objectSpread({}, restOfDrawAnalysis), {}, {
        authorId: authorProfile.id,
        gameAnalysesIds: gameAnalyses.map(_ref => {
          let {
            id
          } = _ref;
          return id;
        })
      });
      state.authorProfiles[authorProfile.id] = authorProfile;
      if (!state.byDraw[key]) {
        state.byDraw[key] = [];
      }
      if (!state.byDraw[key].includes(restOfDrawAnalysis.id)) {
        state.byDraw[key].push(restOfDrawAnalysis.id);
      }
      for (const matchAnalysis of gameAnalyses) {
        state.matchAnalyses[matchAnalysis.id] = _objectSpread(_objectSpread({}, matchAnalysis), {}, {
          authorId: authorProfile.id
        });
        for (const matchId of (_matchAnalysis$gameIn = (_matchAnalysis$gameIn2 = matchAnalysis.gameInfo) === null || _matchAnalysis$gameIn2 === void 0 ? void 0 : _matchAnalysis$gameIn2.sportEvents) !== null && _matchAnalysis$gameIn !== void 0 ? _matchAnalysis$gameIn : []) {
          var _matchAnalysis$gameIn, _matchAnalysis$gameIn2;
          if (!state.byMatchId[matchId]) {
            state.byMatchId[matchId] = [];
          }
          if (!state.byMatchId[matchId].includes(matchAnalysis.id)) {
            state.byMatchId[matchId].push(matchAnalysis.id);
          }
        }
      }
    }
    if (action.payload.normalizedDrawData && Object.keys(state.drawData).length === 0) {
      state.drawData = action.payload.normalizedDrawData;
    }
    state.lastFetchByDraw[key] = (_action$meta$enhanche = action.meta.enhanchedData) === null || _action$meta$enhanche === void 0 ? void 0 : _action$meta$enhanche._svsTimestamp;
    state.drawAnalysesState[key] = ReducerState.Done;
  }).addCase(fetchDrawData.pending, (state, action) => {
    const key = drawKeyFromAction(action);
    state.drawData[key] = ReducerState.Pending;
  }).addCase(fetchDrawData.rejected, (state, action) => {
    const key = drawKeyFromAction(action);
    state.drawData[key] = ReducerState.Rejected;
  }).addCase(fetchDrawData.fulfilled, (state, action) => {
    const key = drawKeyFromAction(action);
    state.drawData = _objectSpread(_objectSpread({}, state.drawData), action.payload);
    state.drawData[key] = ReducerState.Done;
  }).addCase(setDrawData, (state, action) => {
    state.drawData = _objectSpread(_objectSpread({}, state.drawData), action.payload.drawData);
  });
});
setGlobal('svs.components.sportinfo.gameAnalysesRedux.gameAnalysesReducer', gameAnalysesReducer);

 })(window);